<template>
  <b-card v-if="item && item.debtor">
    <h3 class="light-blue">{{ $t("notice_of_assignment_email") }}</h3>

    <b-row class="mt-3">
      <b-col cols="5">
        <label class="font-weight-bolder" for="debtor">{{
          $t("debtor")
        }}</label>

        <b-form-input disabled :value="item.debtor.name" id="debtor">
        </b-form-input>
      </b-col>
      <b-col cols="5" v-if="item.debtor.main_contact_debtor">
        <label class="font-weight-bolder" for="debtor">{{
          $t("recipient")
        }}</label>

        <b-form-input
          id="recipient"
          disabled
          :value="item.debtor.main_contact_debtor.email"
        >
        </b-form-input>
      </b-col>

      <b-col cols="5">
        <label class="font-weight-bolder" for="sender">{{
          $t("sender")
        }}</label>
        <b-form-input id="sender" disabled :value="item.company.name">
        </b-form-input>
      </b-col>
      <b-col cols="5">
        <label class="font-weight-bolder" for="subject">{{
          $t("email_subject")
        }}</label>
        <b-form-input
          id="subject"
          disabled
          :value="'Abtretungserklärung (Rechnung #' + item.invoice_number + ')'"
        >
        </b-form-input>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col class="font-weight-bolder mb-1">
        {{ $t("email_content") }}
      </b-col>
    </b-row>

    <div
      id="pdf"
      style="
        margin: 0;
        padding: 0;
        width: 100%;
        word-break: break-word;
        -webkit-font-smoothing: antialiased;
      "
    >
      <div role="article" aria-roledescription="email" aria-label="" lang="en">
        <table
          style="
            font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;
            width: 100%;
          "
          width="100%"
          cellpadding="0"
          cellspacing="0"
          role="presentation"
        >
          <tbody>
            <tr>
              <td
                align="center"
                style="
                  --bg-opacity: 1;

                  background-color: white !important;
                  font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;
                "
              >
                <table
                  class="sm-w-full"
                  style="
                    font-family: 'Montserrat', Arial, sans-serif;
                    width: 60%;
                    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
                  "
                  cellpadding="0"
                  cellspacing="0"
                  role="presentation"
                >
                  <tbody>
                    <tr>
                      <td
                        align="center"
                        class="sm-px-24"
                        style="font-family: 'Montserrat', Arial, sans-serif"
                      >
                        <table
                          style="
                            font-family: 'Montserrat', Arial, sans-serif;
                            width: 100%;
                          "
                          width="100%"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="sm-px-24"
                                style="
                                  --bg-opacity: 1;
                                  background-color: #ffffff;

                                  border-radius: 4px;
                                  font-family: Montserrat, -apple-system,
                                    'Segoe UI', sans-serif;
                                  font-size: 14px;
                                  line-height: 24px;
                                  padding: 48px;
                                  text-align: left;
                                  --text-opacity: 1;
                                  color: #626262;
                                  color: rgba(98, 98, 98, var(--text-opacity));
                                "
                                bgcolor="rgba(255, 255, 255, var(--bg-opacity))"
                                align="left"
                              >
                                <p style="align-items: end; text-align: end;background:#182c26;padding:10px;">
                                  <img
                                    width="150px"
                                    src="https://uploads-ssl.webflow.com/64a27dbaad92e5a9aaecaae9/64aa91dea4fd064ea738a8c3_logo.svg"
                                    alt=""
                                  />
                                </p>
                                <p style="font-size: 14px; margin-bottom: 0">
                                  {{ $t("to") }}:
                                  <span
                                    style="
                                      font-size: 14px;
                                      margin-top: 0;
                                      --text-opacity: 1;
                                      color: #4397f0;
                                    "
                                  >
                                    {{ item.debtor.name }}
                                    {{ item.debtor.last_name }},
                                  </span>
                                </p>
                                <p style="margin: 0 0 24px; font-size: 14px">
                                  {{
                                    $t(
                                      "we_hereby_inform_you_that_for_reasons_of_efficiency_we_have_outsourced_accounts_receivable_management_to_hiveTerminal_ag"
                                    )
                                  }}
                                  {{
                                    $t(
                                      "we_have_assigned_and_transferred_the_following_invoice_claim_for_the_services_we_have_provided"
                                    )
                                  }}
                                </p>

                                <table
                                  style="width: 100%"
                                  width="100%"
                                  cellpadding="0"
                                  cellspacing="0"
                                  role="presentation"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style="
                                          padding-top: 10px;
                                          padding-bottom: 10px;
                                        "
                                      >
                                        <h3
                                          style="
                                            font-weight: 700;
                                            font-size: 14px;
                                            margin-top: 0;
                                            text-align: left;
                                            margin-bottom: 2px;
                                          "
                                        >
                                          {{ $t("our_details") }}
                                        </h3>
                                        <p
                                          style="font-size: 14px; margin-top: 0"
                                        >
                                          {{ item.company.name }},
                                          {{ item.company.address }}
                                          {{ item.company.address_nr }},
                                          {{ item.company.zip }}
                                          {{ item.company.city }}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr
                                      style="
                                        padding-top: 10px;
                                        padding-bottom: 10px;
                                      "
                                    >
                                      <td style="">
                                        <h3
                                          style="
                                            font-weight: 700;
                                            font-size: 14px;
                                            margin-top: 0;
                                            text-align: left;
                                            margin-bottom: 2px;
                                          "
                                        >
                                          {{
                                            $t(
                                              "payment_of_the_invoice_concerned"
                                            )
                                          }}
                                          #:
                                        </h3>
                                        <p
                                          style="font-size: 14px; margin-top: 0"
                                        >
                                          {{ item.invoice_number }}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colspan="2"
                                        style="
                                          font-family: 'Montserrat', Arial,
                                            sans-serif;
                                        "
                                      >
                                        <table
                                          style="
                                            font-family: 'Montserrat', Arial,
                                              sans-serif;
                                            width: 100%;
                                          "
                                          width="100%"
                                          cellpadding="0"
                                          cellspacing="0"
                                          role="presentation"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style="
                                                  font-family: 'Montserrat',
                                                    Arial, sans-serif;
                                                  font-size: 14px;
                                                "
                                              >
                                                <h3
                                                  style="
                                                    font-weight: 700;
                                                    font-size: 14px;
                                                    margin-top: 10px;
                                                    text-align: left;
                                                    margin-bottom: 2px;
                                                  "
                                                >
                                                  {{
                                                    $t(
                                                      "third_party_to_whom_payment_is_to_be_made"
                                                    )
                                                  }}
                                                </h3>
                                                <p
                                                  style="
                                                    font-size: 14px;
                                                    margin-top: 0;
                                                  "
                                                >
                                                  Aequitex AG, Gubelstrasse
                                                  24, 6330 Zug
                                                </p>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style="
                                                  font-family: 'Montserrat',
                                                    Arial, sans-serif;

                                                  padding-top: 10px;
                                                  padding-bottom: 10px;
                                                  width: 80%;
                                                "
                                                width="80%"
                                              >
                                                <h3
                                                  style="
                                                    font-weight: 700;
                                                    font-size: 14px;
                                                    margin-top: 0;
                                                    text-align: left;
                                                    margin-bottom: 2px;
                                                  "
                                                >
                                                  {{
                                                    $t("payment_coordinates")
                                                  }}
                                                  (IBAN):
                                                </h3>
                                                <p
                                                  style="
                                                    font-size: 14px;
                                                    margin-top: 0;
                                                  "
                                                >
                                                  CH84 0026 7267 1195 0102 E
                                                  <!-- {{$myData['market']->iban}}  -->
                                                </p>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style="
                                                  font-family: 'Montserrat',
                                                    Arial, sans-serif;

                                                  padding-top: 10px;
                                                  padding-bottom: 10px;
                                                  width: 80%;
                                                "
                                                width="80%"
                                              >
                                                <h3
                                                  style="
                                                    font-weight: 700;
                                                    font-size: 14px;
                                                    margin-top: 0;
                                                    text-align: left;
                                                    margin-bottom: 2px;
                                                  "
                                                >
                                                  {{ $t("payment_term") }} :
                                                </h3>
                                                <p
                                                  style="
                                                    font-size: 14px;
                                                    margin-top: 0;
                                                  "
                                                >
                                                  {{ $t("stays_unchanged") }}.
                                                </p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  style="
                                    font-family: 'Montserrat', Arial, sans-serif;
                                    width: 100%;
                                    margint-top: 10px;
                                  "
                                  width="100%"
                                  cellpadding="0"
                                  cellspacing="0"
                                  role="presentation"
                                >
                                  <body>
                                    <tr>
                                      <td
                                        class="sm-px-24"
                                        style="
                                          --bg-opacity: 1;
                                          background-color: #ffffff;

                                          border-radius: 4px;
                                          font-family: Montserrat, -apple-system,
                                            'Segoe UI', sans-serif;
                                          font-size: 14px;
                                          line-height: 24px;

                                          text-align: left;
                                          --text-opacity: 1;
                                          color: #626262;
                                          color: rgba(
                                            98,
                                            98,
                                            98,
                                            var(--text-opacity)
                                          );
                                        "
                                        align="left"
                                      >
                                        <p style="margin: 0 0 24px">
                                          {{
                                            $t(
                                              "please_reply_to_this_email_with_the_text_CONFIRMED_without_deleting_or_changing_the_text_below"
                                            )
                                          }}
                                        </p>
                                        <p style="margin: 0 0 24px">
                                          {{
                                            $t(
                                              "you_confirm_the_terms_of_the_invoice_and_that_we_have_performed_our_contractual_obligations_in_relation_to_said_invoice_to_your_satisfaction"
                                            )
                                          }}
                                          {{
                                            $t(
                                              "you_also_expressly_agree_that_these_new_payment_arrangements_supplement_the_previous_agreement_between_you_and_us_for_this_purpose"
                                            )
                                          }}
                                        </p>
                                        <p style="margin: 0 0 24px">
                                          {{
                                            $t(
                                              "your_other_contractual_rights_and_obligations_towards_us_remain_unaffected"
                                            )
                                          }}
                                          {{
                                            $t(
                                              "only_the_payment_modality_changes_for_you"
                                            )
                                          }}.
                                          {{
                                            $t(
                                              "execution_to_us_is_no_longer_valid_execution_of_the_payment_obligation_unless_execution_was_made_prior_to_the_date_of_this_document"
                                            )
                                          }}.
                                        </p>
                                        <p></p>
                                        <div
                                          class="font-italic mb-2"
                                          style="
                                            font-size: 14px;
                                            font-style: italic;
                                            margin-bottom: 3rem;
                                          "
                                        >
                                          {{
                                            $t(
                                              "if_you_have_any_questions_send_an_email_to"
                                            )
                                          }}

                                          <a
                                            href="mailto:support@hiveterminal.com"
                                            style="color: #4397f0 !important"
                                            >support@aequitex.com</a
                                          >
                                          {{ $t("or_call") }}
                                          <a
                                            href="https://wa.me/41415117660?text=Hello%20I%20have%20questions%20"
                                            class="text-secondary"
                                            style="color: gray"
                                            >whatsapp.</a
                                          >
                                        </div>
                                        <div
                                          class="font-italic"
                                          style="
                                            font-style: italic;
                                            margin-bottom: 3rem;
                                          "
                                        >
                                          {{
                                            $t("this_email_was_sent_through")
                                          }}
                                          <span
                                            v-if="
                                              item.company &&
                                              item.company
                                                .main_contact_person &&
                                              item.company
                                                .main_contact_person[0] &&
                                              item.company
                                                .main_contact_person[0].user
                                            "
                                          >
                                            {{
                                              item.company
                                                .main_contact_person[0].user
                                                .name
                                            }}
                                            {{
                                              item.company
                                                .main_contact_person[0].user
                                                .last_name
                                            }}
                                            <span
                                              v-html="
                                                '(' + item.company.name + ')'
                                              "
                                            ></span>
                                          </span>

                                          <!-- {{$myData['seller']->name}}  -->
                                          via Hive Terminal Platform
                                          {{ $t("at_the") }}
                                          {{ today }}
                                          {{ $t("triggered") }}.
                                        </div>
                                      </td>
                                    </tr>
                                  </body>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <b-row class="mt-2" align="end">
      <b-col>
        <b-button variant="primary" class="mr-2" @click="sendRequest()">
          <feather-icon icon="MailIcon" size="14" class="mr-1" />
          {{ $t("send_request_for_confirmation_to_debtor") }}</b-button
        >
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
export default {
  data() {
    return {
      invoice_id: "",
      invoice_number: "",
      item: null,
    };
  },
  created() {
    this.getInvoice();
  },
  computed: {
    today() {
      let date = new Date();
      return date.toLocaleString().replaceAll("/", "-");
    },
    lang() {
      return this.$store.state.app.company.value.language;
    },
  },
  methods: {
    getInvoice() {
      this.$http.post(`/invoice/show/${this.$route.params.id}`).then((res) => {
        if (res) {
          this.item = res.data.value;
        }
      });
    },
    showSuccessMessage(text) {
      this.$swal({
        position: "center",
        icon: "success",
        title: text,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((res) => {
        this.$router.go(-1);
      });
    },
    sendRequest() {
      let data = {
        invoice_id: this.item.id,
        debtor_name: this.item.debtor.name,
        recipient: this.item.debtor.main_contact_debtor.email,
        sender: "HiveTerminal.ch",
        email_subject: "Abtretung der Rechnung #" + this.item.invoice_number,
        email_content: document.getElementById("pdf").innerText,
        company_id: this.item.company_id,
      };
      this.$http
        .post(`/proofOfOwnership/generateNoticeOfAssignment`, data)
        .catch((err) => {
          if (err) {
            this.$swal({
              position: "center",
              icon: "error",
              title: err.response.data.message,
              showConfirmButton: false,
              timer: 4000,
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        })
        .then((res) => {
          if (res) {
            this.showSuccessMessage(res.data.message);
          }
        });
    },
  },
};
</script>
<style>
.email-content {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-top: 6rem;
  padding-bottom: 6rem;
  padding-left: 10rem;
  padding-right: 10rem;
}
</style>
